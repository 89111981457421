.container-work-with-us {
  text-align: center;
  background-color: #f0f0f0;
  position: relative;
  height: 680px;

  @media screen and (max-width: 991px) {
    height: 850px;
  }

  .work-whit-us-top {
    height: 50%;
    margin-top: 0px;
    background-image: url("../../assets/contenido/Trabaja con Nosotros/fondo.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
  }

  .work-whit-us-bottom {
    height: 50%;
    align-items: center;
    justify-content: center;
    align-content: center;
    display: flex;

    .form-container {
      padding: 20px 0px;
      height: auto;
      border-radius: 20px;
      background-color: white;
      box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 100px;
      width: 100%;
      max-width: 900px;

      @media screen and (max-width: 991px) {
        height: auto;
      }

      @media screen and (max-width: 600px) {
        height: auto;
        width: 100%;
      }

      .container-text-form {
        padding: 15px;
        padding-left: 50px;

        @media screen and (max-width: 1200px) {
          padding: 15px;
          padding-left: 35px;
        }

        @media screen and (max-width: 991px) {
          padding: 25px;
          height: 25%;
        }

        @media screen and (max-width: 600px) {
          padding: 15px;
        }

        h1,
        p {
          color: $color-green-dark;
          width: 100%;
          text-align: left;
        }

        h1 {
          font-size: 35px;
          line-height: 1;

          @media screen and (max-width: 600px) {
            font-size: 30px;
          }
        }

        p {
          font-size: 16px;
          width: 80%;

          @media screen and (max-width: 600px) {
            font-size: 14px;
          }
        }

        .text-marked {
          font-family: Robotobold !important;
          color: $color-green-light;
        }

        .people-chilemat-img {
          position: absolute;
          left: 5%;
          width: 80%;
          bottom: 0;

          @media screen and (max-width: 991px) {
            display: none;
          }
        }
      }

      .container-inputs-form {
        text-align: left;
        width: 80%;
        height: 100%;

        @media screen and (max-width: 991px) {
          height: 75%;
        }

        .work-with-us-form {
          width: 100%;
          height: 100%;
          margin: 0px;
          padding-left: 40px;
          padding-right: 50px;
          align-content: center;
          align-items: center;

          @media screen and (min-width: 992px) {
            display: grid;
          }

          @media screen and (max-width: 1200px) {
            padding-left: 25px;
            padding-right: 35px;
          }

          @media screen and (max-width: 991px) {
            padding: 0vh 25px;
          }

          @media screen and (max-width: 600px) {
            padding: 15px;
          }

          .form-item {
            width: 100%;
            border-top-style: none;
            border-left-style: none;
            border-right-style: none;
            border-bottom: solid 1px $color-gray-black;
            margin: 10px 0px;
            font-size: 16px;

            @media screen and (max-width: 600px) {
              font-size: 14px;
            }

            &::placeholder {
              color: $color-gray-light;
              font-size: 16px;

              @media screen and (max-width: 600px) {
                font-size: 14px;
              }
            }

            &:first-child {
              margin-top: 0%;
            }

            &:focus {
              border: 2px solid $color-green-regular;
              color: $color-green-dark;
              font-size: 16px;

              @media screen and (max-width: 600px) {
                font-size: 14px;
              }
            }
          }

          .phone-item-form {
            width: 50%;
            color: $color-gray-light;
            font-size: 16px;

            @media screen and (max-width: 600px) {
              font-size: 14px;
            }

            input {
              font-size: 16px;

              @media screen and (max-width: 600px) {
                margin: 10px 0px;
                font-size: 14px;
              }

              &:focus {
                border: 2px solid $color-green-regular;
                color: $color-green-dark;
                font-size: 16px;

                @media screen and (max-width: 600px) {
                  font-size: 14px;
                }
              }
            }
          }

          .container-upload {
            border: transparent;
            display: grid;

            label {
              font-size: 16px;
              color: $color-green-light;
            }

            .label-upload-file {
              margin-bottom: 0px;
            }

            .box-container-upload {
              border: solid 1px $color-gray-black;
              height: 60px;
              width: 100%;
              text-align: center;
              align-items: center;
              align-content: center;
              display: flex;

              input[type="file"]::file-selector-button {
                border: 2px solid $color-gray-black;
                background-color: $color-gray-black;
                color: white;
                width: 50%;
                height: 100%;
                transition: 1s;
                cursor: pointer;

                @media screen and (max-width: 1024px) {
                  width: 45%;
                }

                &:hover {
                  background-color: $color-gray-light;
                  border: 2px solid $color-gray-light;
                }
              }

              .form-upload-file {
                width: 90%;
                margin: 0px 5%;
                height: 40px;
                font-size: 15px;
                padding: 0px;

                @media screen and (max-width: 600px) {
                  height: 35px;
                  font-size: 13px;
                }

                @media screen and (min-width: 1650px) {
                  height: 50px;
                  font-size: 18px;
                }
              }
            }
          }

          .form-button {
            border: none;
            width: 100%;
            font-size: 15px;
            color: white;
            font-family: Robotobold;
            background-color: $color-green-regular;
            text-align: center;
            align-content: center;
            align-items: center;
            padding: 10px 0px;
            cursor: pointer;

            @media screen and (max-width: 600px) {
              height: 35px;
              font-size: 13px;
            }

            @media screen and (min-width: 1650px) {
              font-size: 18px;
            }

            span {
              position: absolute;
              width: 20%;
              text-align: right;

              @media screen and (max-width: 500px) {
                width: 15%;
              }

              .send {
                width: 20px;
              }
            }

            &:hover {
              background-color: $color-green-light;
            }
          }
        }
      }
    }
  }
}
