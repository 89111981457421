@import "./_variables.scss";

header {
  top: 0px;
  z-index: 500;
  width: 100%;
  height: 70px;
  background-color: $color-green-regular;
  position: fixed;
  @media screen and (max-width: 600px) {
   height: 60px;
  }
  .rows-header-bottom {
    height: 100%;
    box-shadow: inset 0px 12px 35px -1px rgba(black, 0.2);
    background-image: linear-gradient(
      100deg,
      $color-green-regular 30%,
      $color-green-medium 100%
    );
    @media screen and (max-width: 600px) {
      background-image: linear-gradient(
        100deg,
        $color-green-regular 70%,
        $color-green-dark 100%
      );
    }
    a {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      align-content: center;
      justify-content: center;
      cursor: pointer;
    }
    .col-header {
      height: 100%;
    }
    .logo {
      overflow: hidden;
      align-items: center;
      align-content: center;
      display: flex;
      justify-content: center;
      cursor: pointer;
      height: 100%;
      width: 100%;
      min-width: 155px;
      max-width: 280px;
      position: absolute;
      left: 10px;
      z-index: 1;
      a {
        position: relative;
        z-index: 2;
      }
      img {
        height: 60px;
        @media screen and (max-width: 580px) {
          height: 50px;
        }
      }
    }
    .menu-container {
      align-items: center;
      text-align: -webkit-right;
      text-align: -moz-right;
      text-align: -o-right;
      text-align: -ms-right;
      .navBar-menu {
        line-height: 1;
        font-weight: bold;
        height: 100%;
        width: 100%;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        z-index: 20;
        .menu-icon {
          font-size: 25px;
          color: white;
          background: transparent;
          border: none;
          cursor: pointer;
          position: absolute;
          right: 10%;
          @media screen and (min-width: 992px) {
            display: none;
          }
        }
        .nav-menu.active {
          @media screen and (max-width: 991px) {
            right: 0px;
            opacity: 1;
            transition: all 1s ease;
            z-index: 30;
            align-items: center;
            padding: 0% 10px;
            overflow: hidden;
            overflow-y: auto;
          }
        }
        .nav-menu {
          display: flex;
          list-style: none;
          text-align: center;
          margin: 0px;
          width: 100%;
          height: 100%;
          @media screen and (max-width: 991px) {
            display: flex;
            flex-direction: column;
            z-index: 30;
            background-image: linear-gradient(
              90deg,
              $color-green-regular 60%,
              $color-green-dark 100%
            );
            width: 100%;
            height: calc(100vh - 80px);
            min-height: 500px;
            position: absolute;
            top: 100%;
            right: -100%;
            opacity: 1;
            transition: all 1s ease;
          }

          #unete-link {
            background-color: $color-green-light;
            @media screen and (max-width: 991px) {
              background-color: transparent;
            }
          }
          #usuario-link {
            background-color: $color-orange;
            color: white;
            @media screen and (max-width: 991px) {
              background-color: transparent;
            }
            div {
              align-items: center;
              align-content: center;
              justify-content: center;
              padding: 0.5rem 1rem;
              display: flex;
              height: 100%;
              width: 100%;
              @media screen and (max-width: 991px) {
                justify-content: inherit;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-left: 10px;
              }
            }
          }
          div {
            cursor: pointer;
            position: relative;
            margin: 0px;
            height: 100%;
            width: 25%;
            align-items: center;
            align-content: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            text-transform: uppercase;
            font-size: 13px;
            padding: 0;
            @media screen and (max-width: 991px) {
              width: 100%;
              height: auto;
              padding: 10px 0px;
              justify-content: inherit;
            }
            a {
              color: white;
              text-decoration: none;
              padding: 0.5rem 1rem;
              height: 100%;
              width: 100%;
              border-bottom: 3px solid transparent;
              display: inline-block;
              align-items: center;
              justify-content: center;
              display: flex;
              @media screen and (max-width: 991px) {
                justify-content: inherit;
                height: auto;
                padding: 0.5rem 10px;
              }
            }
            @media screen and (min-width: 1650px) {
              font-size: 14px;
            }
            .submenuDesk {
              width: 100%;
              display: none;
              flex-direction: column;
              list-style-type: none;
              margin: 0px auto;
              top: 100%;
              position: absolute;
              border-radius: 0px 0px 15px 15px;
              background: white;
              overflow: hidden;
              padding: 0px;
              @media screen and (max-width: 991px) {
                position: initial;
                top: auto;
                background-color: transparent;
                border-radius: 0px;
                display: block;
                padding-left: 20px;
              }
              li {
                width: 100%;
                align-items: center;
                justify-content: center;
                align-content: center;
                display: flex;
                border-left: solid 2px rgba($color-green-dark, 0.2);
                a {
                  color: $color-green-dark;
                  display: block;
                  height: 100%;
                  width: 100%;
                  @media screen and (max-width: 991px) {
                    text-align: left;
                    color: white;
                  }
                  &:hover {
                    color: white;
                    background-color: $color-green-light;
                  }
                }
                &:hover {
                  background-color: $color-green-light !important;
                  border-bottom-color: transparent !important;
                  border-bottom-width: 0px !important;
                  border-bottom-style: none !important;
                }
              }
            }

            &:hover {
              @media screen and (min-width: 992px) {
                .submenuDesk {
                  display: flex;
                }
                background-color: $color-green-regular;
                border-bottom-color: transparent !important;
                border-bottom-width: 0px !important;
                border-bottom-style: none !important;
              }
            }
          }
          .activeLink {
            @media screen and (max-width: 991px) {
              border-left: solid 5px $color-green-light;
              color: white;
            }
            .titleLink::after {
              content: "";
              display: block;
              position: absolute;
              height: 104%;
              width: 100%;
              background: transparent;
              border-bottom: solid 5px $color-green-light !important;
              color: #fff;
              @media screen and (max-width: 991px) {
                height: 0px;
                width: 0%;
                border-bottom: solid 3px transparent;
              }
            }
          }
        }
      }
    }
  }
}
