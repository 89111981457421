.section-StoresImg{
    width: 100%;
    padding: 50px 0%;
	justify-content: center;
	align-items: center;
    display: flex;
    flex-direction: column;
    .StoresImg-title{
        text-transform: uppercase;
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        font-size: 100px;
        font-weight: bold;
        position: relative;
        line-height: 1;
        color: rgba($color-gray-light, 0.5);
        overflow: hidden;
        margin-bottom: 25px;
        @media screen and (max-width:600px){
            font-size: 80px;
        }
        @media screen and (max-width:550px){
            font-size: 70px;
        }
        @media screen and (max-width:470px){
            font-size: 60px;
        }
        @media screen and (max-width:410px){
            font-size: 50px;
        }
        @media screen and (max-width:350px){
            font-size: 40px;
        }
        h1{
            color: $color-green-regular;
            position: absolute;
            font-size: 35px;
            justify-content: center;
            align-content: center;
            align-items: center;
            text-align: center;
            display: flex;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: 0;
            @media screen and (max-width:600px){
                font-size: 25px;
            }
            @media screen and (max-width:460px){
                font-size: 18px;
            }
        }
    }
    .carousel-imgs{
        width: 95%;
        max-width: 1900px;
        border: none;
        .swiper-slide{
            align-items: center;
            justify-content: center;
            display: flex;
            margin: 25px 0px;
        }
        .swiper-button-next,
        .swiper-button-prev {
            width: 50px !important;
            height: 50px !important;
            margin-top: 0 !important;
            background-color: $color-green-regular;
            border-radius: 100%;
            z-index: 2 !important;
            &::after{
                color: white;
                font-size: 30px;
                font-weight: bold;
            }
        }
        img{
            width: 95%;
            border-radius: 45px 0px 45px 0px;
        }
    }
}