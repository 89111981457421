.howToBePart-section-container{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative; 
    align-items: center;
    text-align: center;
    align-content: center;
    flex-direction: column !important;
    color: white;
    width: 100%;
    max-width: 2000px;
    margin: 0px auto;
    padding: 120px 0px;
    border-radius: 150px 0vh 0px 0px;
    @media screen and (max-width:767px){
        border-radius: 0px;
    }
    h1, h2{
        text-transform: uppercase;
        line-height: 1;
        width: 100%;
        max-width: 400px;
    }
    h1{
        font-size: 40px;
        margin: 0%;
        color: $color-green-regular;
        @media screen and (max-width:767px){
            font-size: 30px;
        }  
    }
    h2{
        font-size: 25px;
        color: white;
    }
    .howToBePart-cards-container{
        width: 100%;
        max-width: 1150px;
        margin:  60px 0px;
        display: flex;
        justify-content: center;
        @media screen and (max-width:991px){
            max-width: 700px;
        }
        .swiper-slide{
            align-items: center;
            align-content: center;
            justify-content: center;
            display: flex;
        }
        .swiper-button-next, .swiper-button-prev{
            opacity: 0.4;
            color: $color-gray-light !important;
            @media screen and (min-width:992px){
                display: none !important;
            } 
        }
        .swiper-slide:nth-child(1){
            .howToBePart-cards-info{
                background-color: $color-green-light;
            }
        }
        .swiper-slide:nth-child(2){
            .howToBePart-cards-info{
                background-color: $color-green-regular;
            }
        }
        .swiper-slide:nth-child(3){
            .howToBePart-cards-info{
                background-color: $color-green-medium;
            }
        }
        .howToBePart-cards-info{
            height: 280px;
            padding: 30px;
            .howToBePart-img-card{
                height: 40%;
                margin: 20px 0px;
            }
        }
        .howToBePart-cards-fixed{
            height: 280px;
            padding: 30px;
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media screen and (min-width:1650px){
                height: 290px;
            } 
            .howToBePart-img-card{
                height: 80px;
                margin: 15px 0px;
            }
            &:nth-child(1){
                background-color: $color-green-light;
                margin-left: 0px;
            }
            &:nth-child(3){
                background-color: $color-green-medium;
            }
            &:nth-child(1), &:nth-child(3){
                height: 230px;
                padding: 15px 30px;
                @media screen and (min-width:1650px){
                    height: 250px;
                } 
            }
            &:nth-child(2){
                margin-top: 30px;
                background-color: $color-green-regular;
            }
        }
        .howToBePart-cards-info, .howToBePart-cards-fixed{
            max-width: 250px;
            width: 100%;
            border-radius: 30px 0px 30px 30px;
            margin-left: 15px;
            @media screen and (max-width:767px){
                margin-left: 0px;
            }
            
            h3{
                color: white;
                font-size: 25px;
                line-height: 1;
                margin-bottom: 0px;
                @media screen and (min-width:1650px){
                    font-size: 30px;
                }  
                @media screen and (max-width:767px){
                    font-size: 20px;
                }  
            }
            p{
                font-size: 16px;
                margin-bottom: 0px;
                line-height: 1;
                @media screen and (min-width:1650px){
                    font-size: 18px;
                } 
            }
        }
     }
    .howToBePart-button{
        width: 100%;
        text-transform: uppercase;
        max-width: 240px;
        font-size: 20px;
        border-radius: 15px;
        border:none;
        color: $color-white;
        background-color: $color-orange;
        cursor: pointer;
        font-weight: bold;
        line-height: 1;
        padding: 17px 0px;
        @media screen and (min-width:1650px){
            padding: 20px 0px;
            font-size: 25px;
        }  
        @media screen and (max-width:1024px){
            width: 40%;
        }  
        @media screen and (max-width:767px){
            font-size: 18px;
            width: 50%;
        } 
        @media screen and (max-width:499px){
            width: 80%;
        }  
        &:hover{
            background-color: $color-green-regular;
        }
    }
}