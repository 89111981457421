.UserAccess-modal{
    position: fixed;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 100vh;
    min-height: auto;
    padding: 20px 0px;
    z-index: 1000;
    background-color: rgba(0,0,0,0.5);
    align-items: center;
    align-content: center;
    justify-content: center;
    .UserAccess-container{
        border-radius: 2vh;
        box-shadow: 0vh 0vh 0vh .2vh $color-gray-light;
        color: $color-green-dark;
        font-weight: bold;
        line-height: 1;
        width: 35%;
        min-width: 380px;
        background: #fff;
        padding: 5% 2%;
        border-radius: 2vh;
        box-shadow: 0vh 0vh 0vh 0.2vh #C0C2C3;
        align-items: center;
        align-content: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        text-transform: uppercase;
        @media screen and (min-width: 1920px){
            font-size: 2vh;
        }
        @media screen and (max-width:1919px) and (min-width:1650px){
            font-size: 18px;
        }
        @media screen and (max-width:991px){
            padding: 60px 20px;
        }
        @media screen and (max-width: 380px){
            width: 100%;
            min-width: 100%;
        }
        h1{
            font-size: 25px;
            text-align: center;
            color: $color-gray-black;
            @media screen and (min-width: 1920px){
                font-size: 4vh;
            }
            @media screen and (max-width:1919px) and (min-width:1650px){
                font-size: 30px;
            }
        }
        .UserAccess-exit{
            color: $color-gray-black;
            font-weight: bold;
            position: absolute;
            z-index: 10;
            right: 2%;
            top: 0%;
            font-size: 20px;
            cursor: pointer;
            height: 35px;
            width: 35px;
            align-items: center;
            align-content: center;
            justify-content: center;
            display: flex;
            &:hover{
                color: red;
            }
            &:focus{
                box-shadow: inset 0 10px 50px 0 rgba(0, 0, 0, 1);
            }
            @media screen and (min-width: 1920px){
                height: 5vh;
                width: 5vh;
                font-size: 3vh;
            }
            @media screen and (max-width:1919px) and (min-width:1600px){
                height: 45px;
                width: 45px;
                font-size: 30px;
            }
        }
        a{          
            display: contents;
        }
        .UserAccess-options{
            cursor: pointer;
            width: 80%;
            min-width: 200px;
            align-items: center;
            text-align: center;
            display: flex;
            justify-content: center;
            align-content: center;
            padding: 4% 0%;
            margin: 4% 0%;
            background: $color-green-regular;
            color: white;
            &:hover{
                background-color: $color-green-light;
            }
            @media screen and (max-width:991px){
                padding: 15px 0%;
                margin: 15px 0%;
            }
            img{
                height: 18px;
                margin-left: 5%;
                @media screen and (min-width: 1920px){
                    height: 2.2vh;
                }
                @media screen and (max-width:1919px) and (min-width:1650px){
                    height: 22px;
                }
            }
        }
        .last-option{
            margin-bottom: 0px;
        }
    }
}