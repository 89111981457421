.NewStoresSlider-section{
    width: 100%;
    min-height: 460px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    background-color: $color-green-medium;
    padding: 40px 0%;
    h1{
        color: white;
        font-size: 35px;
        line-height: 1;
        margin: 0%;
        @media screen and (min-width:1650px){
            font-size: 40px;
        }
        @media screen and (max-width:767px){
            font-size: 30px;
        }
    }
    .container-NewStoresSlider{
        width: 80%;
        max-width: 1536px;
        padding: 70px 0%;
        align-items: center;
        align-content: center;
        justify-content: center;
        display: flex;
        @media screen and (max-width:1200px){
            width: 100%;
        }
        .swiper-slide{
            align-items: center;
            align-content: center;
            justify-content: center;
            display: flex;
        }
        .swiper-pagination-bullet-active {
            opacity: 1;
            background-color: $color-green-regular;
        }
        .swiper-pagination{
            display: none;
            @media screen and (max-width:500px){
                display: block;
            }
        }
        .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
            bottom: 0%;
        }
        .swiper-button-next, .swiper-button-prev{
            bottom: 0%;
            top: auto;
            opacity: 0.8;
            color: $color-gray-light !important;
            z-index: 11;
            @media screen and (max-width: 500px){
                display: none;
            }
            &::after{
                font-size: 25px;
            }
        }
        .swiper-button-next{
            right: 45%;
        }
        .swiper-button-prev{
            left: 45%;
        }
        .NewStoresSlider-info-container{
            cursor: pointer;
            width: 85%;
            min-width: 300px;
            height: 370px;
            background-color: white;
            box-shadow: 0px 0px 5px -1px $color-gray-black;
            overflow: hidden;
            border-radius: 2%;
            @media screen and (max-width: 991px){
                width: 300px;
            }
            @media screen and (min-width:1650px){
                height: 430px;
            }
            @media screen and (max-width: 300px){
                width: 100%;
                min-width: 100%;
            }
            .NewStoresSlider-img-container{
                overflow: hidden;
                height: 50%;
                transition: height .6s;
                background-size: cover;
                background-repeat: no-repeat;
                @media screen and (max-width:320px){
                    height: 40%;
                }
            }
            .NewStoresSlider-text-container{
                height: 50%;
                transition: height .6s;
                padding:15px;
                text-align: left;
                line-height: 1;
                @media screen and (max-width:320px){
                    height: 60%;
                }
                p{
                    margin: 0px;
                }
                .NewStoresSlider-date{
                    font-size: 10px;
                    color: $color-green-light;
                    text-transform: uppercase;
                    font-weight: bolder;
                    @media screen and (min-width:1650px){
                        font-size: 14px;
                    }
                }
                .NewStoresSlider-title{
                    text-transform: uppercase; 
                    margin-top: 3%;
                    margin-bottom: 5%;
                    font-size: 18px;
                    color: $color-green-dark;
                    font-family: Robotoblack !important;
                    @media screen and (min-width:1650px){
                        font-size: 22px;
                    }
                }
                .NewStoresSlider-cards-paragraph{
                    opacity: 0;
                    transition: opacity .6s;
                    font-size: 14px;
                    line-height: 1.2;
                    color: $color-green-dark;
                    @media screen and (min-width:1650px){
                        font-size: 16px;
                    }
                }
            }
            &:hover{
                .NewStoresSlider-img-container{
                    box-shadow: inset 2px 2px 500px 2px rgba(0, 0, 0, 0.5);
                }
                @media screen and (min-width:768px){
                    .NewStoresSlider-img-container{
                        height: 20%;
                    }
                    .NewStoresSlider-text-container{
                        height: 80%;
                        .NewStoresSlider-cards-paragraph{
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}