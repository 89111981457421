.timeline-our-history-section{
  width: 100%;
  max-width: 2000px;
  margin: 0px auto !important;
  height: auto !important;
}
.mySwiper {
  position: relative;
  height: auto !important;
  width: 100%;
  align-content: center;
  justify-content: center;
  display: flex;
  .swiper-wrapper { 
    transition: 2s cubic-bezier(.68,-0.4,.27,1.34) 1s !important; 
    height: auto !important;
  }
  .swiper-slide {
    height: auto !important;
    width: 100%;
    position: relative;
    color: white;
    overflow: hidden;
    background-color: #249f6c;
    justify-content: center;
    display: flex;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 80px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding-bottom: 60px;
    }
    &-content {
      position: absolute;
      text-align: center;
      width: 80%;
      max-width: 310px;
      right: 50%;
      top: 13%;
      transform: translate(50%, 0);
      font-size: 12px;
      z-index: 2;
      @media screen and (min-width: 768px) {  
        right: 30%;
        top: 50%;
        transform: translateY(-50%);
        width: 310px;
        font-size: 11px;
        text-align: right;
      }
    }
    .timeline-year,.timeline-title, .timeline-text{
      width: 100%;
      max-width: 450px;
    }
    .timeline-year {
      display: block;
      font-size: 60px;
      transform: translate3d(20px, 0, 0);
      color:$color-green-dark;
      font-weight: 300;
      opacity: 0;
      transition: .2s ease .4s;
      line-height: 1.5;
      @media screen and (max-width: 1024px) {
       font-size: 45px;
      }
      @media screen and (min-width: 768px) {
        line-height: 1;
      }
    }
    .timeline-title {
      text-transform: uppercase;
      color:white;
      font-weight: 800;
      font-size: 30px;
      line-height: 1;
      margin: 0 0 30px;
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: .2s ease .5s;
      @media screen and (max-width: 1024px) {
        font-size: 25px;
      }
    }
    .without-margin{
      margin: 0px;
    }
    .timeline-text {
      line-height: 1.2;
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: .2s ease .6s;
      font-size: 17px;
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
    &-active {
      .timeline-year {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.6s;
      }  
      .timeline-title {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.7s;
      }      
      .timeline-text {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.8s;
      }
    }
  }
  .chilemat-history-slide{
    background-color: transparent;
    background-image: url("../../assets/contenido/Nuestra\ Empresa/chilemat-history.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    .space-timeline-left{
      width: 42%;
      @media screen and (max-width: 1217px) {
        width: 32%;
      }
      @media screen and (max-width: 960px) {
        width: 20%;
      }
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .text-chilemat-history{
      width: 40%;
      padding: 0px 3vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 1217px) {
        width: 50%;
      }
      @media screen and (max-width: 960px) {
        width: 62%;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        max-width: 600px;
        height: 100%;
        margin: 0px auto;
        overflow-y: auto;
      }
      h1{
        width: 100%;
        text-align: right;
        color: white;
        @media screen and (max-width: 767px) {
          text-align: center;
        }
        @media screen and (min-width: 768px) {
          line-height: 1.5;
          margin: 0px;
        }
      }
      p{
        text-align: justify;
      }
      .timeline-year, .timeline-text{
        max-width: 450px;
      }
      .read-more-timeline{
        display: block;
        @media screen and (max-width: 767px) {
          display: none ;
        }
      }
      .text-buttons-timeline-container{
        @media screen and (min-width: 768px) {
          display: none;
        }
        display: block;
        text-align: center;
        align-items: center;
        align-content: center;
        justify-content: center;
        .see-more-button, .see-less-button{
          text-align: center;
          border: solid;
          cursor: pointer;
          padding: 10px;
          &:hover{
            box-shadow: inset 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
          }
        }
        .see-less-button{
          display: none;
        }
      }
    }
  }
  .img-container-timeline{
    width: 50%;
    align-items: center;
    align-content: center;
    text-align: center;
    display: flex;
    justify-content: flex-end !important;
    text-align: -webkit-right;
    text-align: -moz-right;
    text-align: -o-right; 
    text-align: -ms-right;
    overflow: hidden;
    img{
      width: 100%;
      min-width: 380px;
      max-width: 600px;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .timeline-img-mobile{
    display: none;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      display: flex;
      justify-content: center;
      width: 100%;
      text-align: -webkit-center;
      text-align: -moz-center;
      text-align: -o-center; 
      text-align: -ms-center;
    }
    img{
      margin-top: 10px;
      width: 100%;
      max-width: 450px;
    }
  }
  .text-container-timeline{
    width: 50%;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: right;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 768px) {
      align-items: flex-start;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      height: auto;
      text-align: center;
      padding:0px 15px;
    }
  }
  .swiper-pagination{
    justify-content: space-around;
    display: none !important;
    font-weight: 300;
    font-size: 18px;
    @media screen and (min-width: 768px){
      display: flex !important;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0px;
      top: -35px;
      height: 2px;
      width: 100%;
      background-color: rgba($color-gray-light, .8);
    }
    &-bullet {
      width: auto;
      height: auto;
      border-radius: inherit;
      text-align: center;
      opacity: 1 !important;
      background: transparent !important;
      color: white;
      position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -38px;
      left: 50%;
      width: 7px;
      height: 7px;
      border-radius: 100%;
      background-color: $color-orange;
      transition: .2s;
      transform: scale(0); 
    }
    
    &-active {
      color: $color-orange;

      &::before { 
        transform: scale(1); 
      }
    }
  }
  }
  .swiper-button-next,
  .swiper-button-prev {
    top: auto !important;
    bottom: 25px;
    width: 20px !important;
    height: 30px !important;
    margin-top: 0 !important;
    z-index: 2 !important;
    transition: .2s !important;
    &::after{
      color: white;
      font-size: 30px;
    }
    @media screen and (min-width: 768px) {
      display: none !important;
    }
  }
  .swiper-button-prev {
    left: 30%;
    &:hover { transform: translateX(-3px); }
  }
  .swiper-button-next {
    right: 30%;
    &:hover { transform: translateX(3px); }
  }
}

