.footer-2 {
  width: 100%;
  max-width: 100%;
  margin: 0%;
  padding: 0px;
  background-color: transparent;
  color: white;
  line-height: 1.2;
  position: relative;
  z-index: 1;
  .row-footer2 {
    padding: 15px 0px;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .newsletter-container {
    background-color: $color-green-dark;
    width: 100%;
    .newsletter-row {
      width: 100%;
      max-width: 1320px;
      .newsletter-col {
        padding: 30px 0px;
        max-width: 100%;
        position: relative;
        align-items: center;
        justify-content: center;
        align-content: center;
        display: flex;
      }
      .newsletter-text {
        flex-direction: column;
        @media screen and (max-width: 991px) {
          padding: 0px 10px;
        }
        h1,
        p {
          margin: 0px;
          width: 100%;
          max-width: 266px;
          text-align: right;
          @media screen and (max-width: 991px) {
            text-align: left !important;
          }
        }
        h1 {
          color: $color-orange;
          text-transform: uppercase;
          font-size: 25px;
        }
        p {
          padding-left: 20%;
          @media screen and (max-width: 991px) {
            padding-left: 0px;
          }
        }
      }
      .newsletter-form {
        padding: 0px 10px;
        flex-direction: column;
        @media screen and (max-width: 580px) {
          margin-top: 20px;
        }
        @media screen and (max-width: 300px) {
          flex-direction: column;
        }
        .form-container {
          width: 100%;
          align-items: center;
          justify-content: center;
          align-content: center;
          display: flex;
          @media screen and (max-width: 580px) {
            flex-direction: column;
          }
          input,
          button {
            height: 40px;
            border-radius: 1vh;
            @media screen and (max-width: 300px) {
              margin-top: 10px;
            }
          }
          input {
            width: 90%;
            max-width: 345px;
            color: $color-gray-light;
            border: solid 2px $color-gray-light;
            background-color: transparent;
            padding: 0px 10px;
            &:focus {
              border-style: none;
              outline: solid 2px $color-orange;
              color: white;
              border-radius: 1vh;
            }
          }
          button {
            width: 25%;
            max-width: 130px;
            min-width: 110px;
            margin-left: 3%;
            font-size: 18px;
            background-color: $color-orange;
            border: none;
            cursor: pointer;
            &:hover {
              background-color: $color-green-regular;
            }
            @media screen and (max-width: 580px) {
              margin-top: 10px;
            }
          }
        }
        #newsletter-validation {
          color: red;
          width: 100%;
          text-align: center;
        }
      }
      .newsletter-img {
        @media screen and (max-width: 991px) {
          display: none;
        }
        img {
          width: 95%;
          max-width: 320px;
          position: absolute;
          bottom: -5px;
        }
      }
    }
  }
  .footer-info-container {
    width: 100%;
    background-image: linear-gradient(
      90deg,
      $color-green-regular,
      80%,
      $color-green-dark 100%
    );
    .info-row {
      width: 100%;
      max-width: 1320px;
      height: auto;
      padding: 15px 0px;
      .footer-info-col {
        padding: 0px 10px;
        align-items: center;
        align-content: center;
        display: flex;
        flex-direction: column;
        position: relative;
        @media screen and (max-width: 991px) {
          margin-bottom: 20px;
          &:last-child {
            margin: 0%;
          }
        }
        @media screen and (max-width: 768px) {
          &:nth-child(3),
          &:last-child {
            margin: 0%;
          }
        }
        @media screen and (max-width: 576px) {
          margin-bottom: 15px;
          &:last-child {
            margin: 0%;
          }
        }
      }
      .footer-info-menu {
        h3,
        ul {
          width: 100%;
          max-width: 200px;
          @media screen and (max-width: 575px) {
            max-width: 210px;
          }
        }
        h3 {
          text-transform: uppercase;
          color: white;
          font-size: 20px;
          line-height: 1.2;
        }
        ul {
          padding: 0;
          list-style: none;
          text-decoration: none;
          li {
            margin-bottom: 6px;
          }
          a {
            font-size: 14px;
            color: $color-white;
            &:hover {
              color: $color-orange;
            }
          }
        }
      }
      .footer-info-social {
        align-items: center;
        align-content: center;
        display: flex;
        flex-direction: column;
        h3,
        ul {
          width: 100%;
          max-width: 120px;
          @media screen and (max-width: 575px) {
            max-width: 210px;
          }
        }
        h3 {
          text-transform: uppercase;
          color: white;
          font-size: 20px;
          line-height: 1.2;
        }
        .social-network {
          list-style: none;
          align-items: center;
          align-content: center;
          display: flex;
          margin: 0 auto;
          padding: 0px;
          justify-content: space-between;
          li {
            cursor: pointer;
            align-items: center;
            align-content: center;
            display: flex;
            a {
              display: contents;
            }
            img {
              height: 33px;
              transition: height 0.5s;
            }
            &:hover {
              img {
                height: 35px;
              }
            }
          }
        }
      }
      .footer-info-contact {
        .container-contact {
          width: 100%;
          max-width: 300px;
          align-items: center;
          align-content: center;
          justify-content: center;
          display: flex;
          padding-top: 10px;
          padding-bottom: 10px;
          &:first-child {
            padding-top: 0px !important;
          }
          div {
            width: 90%;
            p {
              margin: 0px;
            }
          }
          img {
            height: 35px;
            margin-right: 5%;
          }
        }
      }
      .telefono-footer {
        margin-left: 3px;
      }
      .footer-info-logo {
        justify-content: center;
        img {
          width: 100%;
          max-width: 280px;
        }
      }
    }
  }
  .copyright-container {
    background-color: $color-white;
    font-family: RobotoBlack;
    text-align: center;
    font-size: 14px;
    width: 100%;
    color: $color-green-dark;
    @media screen and (min-width: 1650px) {
      font-size: 18px;
    }
    @media screen and (max-width: 600px) {
      font-size: 13px;
    }
    p {
      margin: 0%;
    }
  }
  .signature-footer {
    background-color: $color-white;
    color: $color-green-dark;
    width: 100%;
    height: auto;
    font-size: 14px;
    justify-content: center;
    @media screen and (min-width: 1650px) {
      font-size: 18px;
    }
    .signature-section {
      width: 85%;
      max-width: 1650px;
      height: auto;
      position: relative;
      justify-content: center;
      @media screen and (max-width: 991px) {
        width: 98%;
        padding-top: 15px;
        padding-bottom: 15px;
      }
      .signature-container {
        height: 100%;
        align-items: center;
        align-content: center;
        justify-content: center;
        display: flex;
        padding: 10px 0px;
        p {
          margin: 0px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          a {
            margin-left: 6px;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}
