.modal-form{
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 20;
    left: 0px;
    top: 0px;
    right:0;
    bottom:0;
    background-color:rgba(255,255,255,0.5);
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    &:target{
        opacity:1;
        pointer-events:auto;
      }    
    .modal-content{    
        height: 80%;
        width: 50%;
        padding: 0px 10%;
        color: #FFFFFF;
        background: $color-green-regular;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        text-align: center;
        align-items: center;
        align-content: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        font-size: 2vh;
        line-height: 1.2;
        @media screen and (max-width:1024px){
            width: 60%;
            font-size: 14px;
         }  
         @media screen and (max-width:800px){
            width: 70%;
         }
         @media screen and (max-width:691px){
            width: 80%;
         }
         @media screen and (max-width:600px){
            width: 90%;
         }  
         @media screen and (max-width:500px){
            width: 100%;
         }  
         .check-icon{
             border: solid ;
             height: 7vh;
             width: 7vh;
             border-radius: 100%;
             align-items: center;
             justify-content: center;
             align-content: center;
             display: flex;
             margin-bottom: 4%;
             @media screen and (max-width:600px){
                height: 30px;
                width: 30px;
                border: solid 2px;
             }
             img{
                 height: 100%;
             }
         }
        h1{
            color:white;
            font-size: 4vh;
            @media screen and (max-width:600px){
                font-size: 1.8em;
            }  
        }
        .buttonConfirmation{
            background-color:$color-green-light;
            color: #FFFFFF;
            border-radius: 1vh;
            border: none;
            width: 80%;
            height: 5vh;
            font-size: 2vh;
            cursor: pointer;
            margin-top: 3%;
            font-weight: bolder;
            &:hover{
                background-color: $color-green-medium;
            }
            &:focus{
                box-shadow: inset 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
            }
            @media screen and (max-width:1024px){
                font-size: 14px;
            }
            @media screen and (max-width:600px){
                height: 35px;
             }  
        }
        .logo-container{
            margin-top: 5%;
            .logo{
                width: 60%;
                @media screen and (max-width:600px){
                    width: 200px;
                 }  
            }
        }
    }
}