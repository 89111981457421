.container-carrusel-supplier{
    width: 100%;
    padding: 50px 0%;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    .SupplierSlider-title{
        text-transform: uppercase;
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        font-size: 100px;
        font-weight: bold;
        position: relative;
        line-height: 1;
        color: rgba($color-gray-light, 0.5);
        overflow: hidden;
        margin-bottom: 25px;
        @media screen and (max-width:680px){
            font-size: 80px;
        }
        @media screen and (max-width:550px){
            font-size: 70px;
        }
        @media screen and (max-width:470px){
            font-size: 60px;
        }
        @media screen and (max-width:410px){
            font-size: 50px;
        }
        @media screen and (max-width:350px){
            font-size: 40px;
        }
        h1{
            color: $color-green-regular;
            position: absolute;
            font-size: 35px;
            justify-content: center;
            align-content: center;
            align-items: center;
            text-align: center;
            display: flex;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: 0;
            @media screen and (max-width:600px){
                font-size: 25px;
            }
            @media screen and (max-width:460px){
                font-size: 18px;
            }
        }
    }
    .container-supplier{
        width: 95%;
        max-width: 1900px;
        padding: 10px 0px;
        position: relative; 
        @media screen and (max-width: 600px){
            width: 100%;
        }
        .swiper-slide{
            align-items: center;
            align-content: center;
            justify-content: center;
            display: flex;
            margin: 15px 0px;
        }
        .swiper-button-next, .swiper-button-prev{
            opacity: 0.8;
            color: $color-gray-light !important;
            
        }
        @media screen and (min-width: 1650px){
            .swiper-button-next, .swiper-button-prev{
                &::after{
                    font-size: 5vh;
                }
            }
            .swiper-button-next{
                right: 1%;
            }
            .swiper-button-prev{
                left: 1%;
            }
        }
        .container-img{
            border-radius: 15px;
            overflow: hidden;
            box-shadow: 0px 0px 15px 0px $color-gray-light;
            width: 80%;
            cursor: pointer;
            &:hover{
                box-shadow: 0px 0px 20px 2px $color-orange;
            }
            img{
                height: auto;
                width: 100%;
            }
        }
    }
}