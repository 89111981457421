.welcomehome-section{
    height: auto;
    position: relative;
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction:column;
    .welcomehome-slider{
        height: auto;
        min-height: 75px;
        width: 100%;
        max-width: 2000px;
        .swiper-slide{
            width: 100% !important;
            position: relative;
            align-items: center;
            align-content: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            .links_banners{
                width: 100% !important;
            }
            img{
                display: block;
                height: auto;
                margin: 0 auto;
                width: 100%;
            }
           
        }
        //pagination
        
        .swiper-pagination-bullets{
            right: 1% !important;
            .swiper-pagination-bullet{
                height: 10px;
                width: 10px;
                margin: 8px;
                opacity: .9 !important;
                background-color: $color-orange;
            }
            .swiper-pagination-bullet-active {
                opacity: 1 !important;
                background-color: white;
            }
        }
        .swiper-pagination, .swiper-pagination-clickable, .swiper-pagination-bullets, .swiper-pagination-bullets-dynamic{
            width: 20px !important;
            top: 25%;
            bottom: auto;
            right: 1% !important;
            left: auto !important;
            height: 50%;
            align-items: center;
            align-content: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
        }
        
    }
    .welcomehome-row-bottom{
        position: absolute;
        bottom: 0px;
        z-index: 10;
        width: 100%;
        max-width: 2000px;
        height: 80px;
        display: flex;
        @media screen and (min-width:1650px){
            height: 85px;
        }
        @media screen and (max-width:1125px){
            position: initial !important;
        }
        @media screen and (max-width:680px){
            height: 60px !important;
            min-height: 60px !important;
        }
        .welcomehome-img-scroll{
            padding: 0px 25px;
            width: 40%;
            display: flex;
            align-items: center;
            @media screen and (max-width:1240px){
                    width: 35%;
            }
            @media screen and (max-width:1190px){
                width: 30%;
            }
            @media screen and (max-width:1125px){
                display: none;
            }
            img{
                height: 70%;
            }
        }
        .welcomehome-menu-bottom{
            border-radius: 1000px 0vh 0vh 0vh;
            height: 100%;
            width: 60%;
            align-items: center;
            align-content: center;
            justify-content: center;
            display: flex;
            overflow: hidden;
            line-height: 1;
            text-transform: uppercase;
            @media screen and (max-width:1240px){
                    width: 65%;
            }
            @media screen and (max-width:1190px){
                width: 70%;
            }
            @media screen and (max-width:1125px){
                width: 100%;
                border-radius: 0%;
            }
            a{
                display: contents;
            }
            .light-green, .medium-green, .dark-green{
                cursor: pointer;
                height: 100%;
                width: 33.3%;
                align-items: center;
                align-content: center;
                justify-content: center;
                display: flex;
                &:hover{
                    background-color: $color-green-regular;
                }
            }
            .light-green{
                background-color: $color-green-light;
            }
            .medium-green{
                background-color: $color-green-medium;
            }
            .dark-green{
                background-color: $color-green-dark;
            }
            .contenido-menu{
                align-items: center;
                align-content: center;
                justify-content: center;
                display: flex;
                height: 75%;
                width: 70%;
                @media screen and (max-width:1024px){
                    width: 75%;
                }
                @media screen and (max-width:951px){
                    width: 80%;
                }
                @media screen and (max-width:875px){
                    width: 85%;
                }
                @media screen and (max-width:835px){
                    width: 90%;
                }
                @media screen and (max-width:767px){
                    width: 95%;
                }
                @media screen and (max-width:680px){
                    height: 100%;
                    flex-direction: column;
                }
                img{
                    height: 70%;
                    margin-left: 5%;
                    @media screen and (max-width:767px){
                        height: 60%;
                        margin-left: 3%;
                    }
                    @media screen and (max-width:680px){
                        height: 35%;
                        margin-left: 0%;
                        margin-top: 2%;
                    }
                }
                .text-menu{
                    width: 80%;
                    text-align: right;
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                    font-weight: bold;
                    @media screen and (max-width:1080px){
                        width: auto;
                    }
                    @media screen and (max-width:680px){
                        align-items: center;
                        align-content: center;
                        text-align: center;
                    }
                    h3, p{
                        margin: 0%;
                    }
                    h3{
                        font-size: 16px;
                        color: white;
                        font-family: Robotoblack !important;
                        @media screen and (min-width:1650px){
                            font-size: 18px;
                        }
                        @media screen and (max-width:767px){
                            font-size: 13px;
                        }
                        @media screen and (max-width:680px){
                            display: none;
                        }
                    }
                    p{
                        line-height: 1.2;
                        color: $color-green-dark;
                        font-size: 13px;
                        @media screen and (min-width:1650px){
                            font-size: 15px;
                        }
                        @media screen and (max-width:767px){
                            font-size: 11px;
                            line-height: 1;
                        }
                    }
                }
                .dark-green-text-menu{
                    p{
                        color: $color-green-light !important;
                    }
                }
                .medium-green-text-menu{
                    width: auto;
                }
            }
        }
    }
}