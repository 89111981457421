@font-face {
  font-family: "Robotoblack";
  src: url(./assets/fonts/Roboto-Black.ttf);
}
@font-face {
  font-family: "Robotoregular";
  src: url(./assets/fonts/Roboto-Regular.ttf);
}
@font-face {
  font-family: "Robotolight";
  src: url(./assets/fonts/Roboto-Light.ttf);
}
@font-face {
  font-family: "Robotomedium";
  src: url(./assets/fonts/Roboto-Medium.ttf);
}
@font-face {
  font-family: "Robotobold";
  src: url(./assets/fonts/Roboto-Bold.ttf);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1, h2, h3, h4, h5, h6, strong{
  font-family: "Robotobold" !important;
}
*{
  font-family: "Robotoregular" !important;
  scroll-behavior: smooth;
}
#root{
  max-width: 100% !important;
  overflow: hidden;
  padding-top: 60px;
}