.swiper-slide{
  align-items: center;
  align-content: center;
  justify-content: center;
  display: flex;
  flex-flow: wrap;
}
.swiper-button-next, .swiper-button-prev{
  opacity: 0.4;
  color: $color-gray-light !important;
  @media screen and (min-width:1431px){
      display: none !important;
  } 
}
.CardsMision-Section {
  min-height: 427px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  .container-card-mision {
    padding: 40px 0px;
    width: 100%;
    max-width: 1850px;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-flow: wrap;
    .container-slide {
      cursor: pointer;
      overflow: hidden;
      padding: 0%;
      width: 100%;
      max-width: 300px;
      margin: 10px;
      @media screen and (min-width: 1650px) {
        max-width: 335px;
      }
      @media screen and (max-width: 380px) {
        padding: 10px 0px;
      }
      .cards-info {
        position: relative;
        overflow: hidden;
        border-radius: 15px;
        width: 100%;
        height: auto;
        text-align: center;
        .frontFace-card,
        .backFace-card {
          width: 100%;
          height: 100%;
        }
        .frontFace-card {
          width: 100%;
          img {
            width: 100%;
          }
        }
        /* Style the back side */
        .backFace-card {
          position: absolute;
          top: 0px;
          color: white;
          padding: 20px 30px;
          font-size: 18px;
          text-align: left;
          line-height: 1.2;
          align-items: center;
          align-content: center;
          display: flex;
          flex-direction: column;
          @media screen and (min-width: 1650px) {
            font-size: 20px;
          }
          @media screen and (max-width: 300px) {
            font-size: 13px;
            padding: 15px;
          }
          h3 {
            text-transform: uppercase;
            font-family: Robotobold !important;
            color: white;
            text-align: center;
            margin: 0px auto;
            margin-bottom: 15px;
            font-size: 35px;
            @media screen and (min-width: 1650px) {
              font-size: 40px;
            }
            @media screen and (max-width: 300px) {
              font-size: 25px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
