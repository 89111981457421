.section-EventsImg{
    width: 100%;
    padding: 50px 0%;
	justify-content: center;
	align-items: center;
    display: flex;
    flex-direction: column;
    .EventsImg-title{
        text-transform: uppercase;
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        font-size: 120px;
        font-weight: bold;
        position: relative;
        line-height: 1;
        color: rgba($color-gray-light, 0.5);
        overflow: hidden;
        margin-bottom: 50px;
        @media screen and (max-width:600px){
            font-size: 80px;
        }
        @media screen and (max-width:460px){
            font-size: 60px;
        }
        h1{
            color: $color-green-regular;
            position: absolute;
            font-size: 35px;
            justify-content: center;
            align-content: center;
            align-items: center;
            text-align: center;
            display: flex;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: 0;
            @media screen and (max-width:600px){
                font-size: 25px;
            }
            @media screen and (max-width:460px){
                font-size: 18px;
            }
        }
    }
    .carousel-imgs{
        width: 95%;
        max-width: 1900px;
        border: none;
        .swiper-button-next,
        .swiper-button-prev {
            width: 30px !important;
            height: 35px !important;
            margin-top: 0 !important;
            z-index: 2 !important;
            &::after{
            color: white;
            font-size: 40px;
            }
        }
        img{
            width: 100%;
        }
    }
}