.SliderHomeTestimony-section{
    width: 100%;
    padding: 45px 0%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    @media screen and (max-width:991px){
        padding: 30px 0%;
    }
    .SliderHomeTestimony-title{
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        font-size: 100px;
        font-weight: bold;
        position: relative;
        line-height: 1;
        color: rgba($color-gray-light, 0.5);
        overflow: hidden;
        @media screen and (max-width:680px){
            font-size: 80px;
        }
        @media screen and (max-width:550px){
            font-size: 70px;
        }
        @media screen and (max-width:470px){
            font-size: 60px;
        }
        @media screen and (max-width:410px){
            font-size: 50px;
        }
        @media screen and (max-width:350px){
            font-size: 40px;
        }
        h1{
            color: $color-green-regular;
            position: absolute;
            font-size: 35px;
            align-content: center;
            justify-content: center;
            align-content: center;
            align-items: center;
            text-align: center;
            display: flex;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: 0;
            @media screen and (max-width:991px){
                font-size: 30px;
            }
            @media screen and (max-width:767px){
                font-size: 25px;
            }
            @media screen and (max-width:460px){
                font-size: 18px;
            }
        }
    }
    .testimony-row-cards{
        width: 80%;
        max-width: 1350px;
        padding: 10px 0px;
        align-items: center;
        align-content: center;
        justify-content: center;
        display: flex;
        @media screen and (max-width:1199px){
            width: 100%;
        }
        .testimony-col-cards{
            align-content: center;
            justify-content: center;
            display: flex;
            margin: 0px;
        }
        .swiper-button-next, .swiper-button-prev{
            opacity: 0.8;
            color: $color-gray-light !important;
            
        }
    }
    .testimony-info-container{
        position: relative;
        height: auto;
        min-height: 230px;
        max-width: 420px;
        width: 100%;
        overflow: hidden;
        display: flex;
        border-radius: 20px;
        @media screen and (min-width:1650px){
            min-height: 260px;
            max-width: 560px;
        }
        @media screen and (max-width: 480px){
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-content: center;
            height: fit-content;
        }
        .testimony-img-container{
            width: 40%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 15px;
            max-height: 230px;
            @media screen and (min-width:1650px){
                max-height: 260px;
            }
            @media screen and (max-width: 480px){
                width: 100%;
                max-width: 180px;
                height: 300px;
            }
        }
        .testimony-text-container{
            position: relative;
            width: 65%;
            line-height: 1;
            min-height: 230px;
            height: 100%;
            padding: 0px 5px 47px 12px;
            font-weight: bold;
            @media screen and (min-width:1650px){
                padding-bottom: 50px;
            }
            @media screen and (max-width: 480px){
                width: 100%;
                padding: 10px 30px;
            }
            h3, p{
                margin-bottom: 3px;
            }
            .testimony-cards-paragraph{
                font-size: 12px;
                line-height: 1.2;
                color: $color-gray-black;
                @media screen and (min-width:1650px){
                    font-size: 15px;
                }
                @media screen and (max-width: 480px){
                    position: initial !important;
                    text-align: left;
                }
            }
            .names-container{
                position: absolute;
                left: 4%;
                right: 0%;
                bottom: 0%;
                color: $color-green-regular;
                text-transform: uppercase;
                @media screen and (max-width: 480px){
                    position: initial !important;
                    margin-top: 15px;
                }
                h3, p{
                    margin: 0px;
                    @media screen and (max-width: 480px){
                        margin-bottom: 3px;
                    }
                }
                p{
                    font-size: 12px;
                    font-family: Robotoblack !important;
                    @media screen and (min-width:1650px){
                        font-size: 15px;
                    }
                }
                h3{
                    color: $color-green-regular;
                    text-transform: uppercase; 
                    font-size: 18px;
                    font-family: Robotoblack !important;
                    @media screen and (min-width:1650px){
                        font-size: 20px;
                    }
                }
            }
        }
    }
}