.container-directory {
  position: relative;
  width: 100%;
  height: auto;
  text-align: center;
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  .directory-title {
    text-transform: uppercase;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    font-size: 100px;
    font-weight: bold;
    position: relative;
    line-height: 1;
    color: rgba($color-gray-light, 0.5);
    overflow: hidden;
    margin-bottom: 50px;
    @media screen and (max-width: 680px) {
      font-size: 80px;
    }
    @media screen and (max-width: 550px) {
      font-size: 70px;
    }
    @media screen and (max-width: 470px) {
      font-size: 60px;
    }
    @media screen and (max-width: 410px) {
      font-size: 50px;
    }
    @media screen and (max-width: 350px) {
      font-size: 40px;
    }
    h1 {
      color: $color-green-regular;
      position: absolute;
      font-size: 40px;
      justify-content: center;
      align-content: center;
      align-items: center;
      text-align: center;
      display: flex;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: 0;
      @media screen and (max-width: 991px) {
        font-size: 35px;
      }
      @media screen and (max-width: 600px) {
        font-size: 30px;
      }
    }
  }
  .directory-row {
    width: 100%;
    max-width: 1000px;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 991px) {
      max-width: 400px;
    }
    @media screen and (max-width: 400px) {
      max-width: 290px;
    }
    .directory-col1 {
      .container-col-cards {
        margin: 0px 10px;
        @media screen and (max-width: 991px) {
          margin: 0px;
        }
        .container-primary::before {
          border-radius: 20px 20px 0px 0px;
        }
        &:nth-child(1) .container-primary::before {
          background-color: #f7b85c;
        }
        &:nth-child(2) .container-primary::before {
          background-color: $color-green-medium;
        }
      }
    }
    .directory-col2 {
      @media screen and (max-width: 991px) {
        justify-content: initial !important;
        margin-top: 80px;
      }
      @media screen and (max-width: 400px) {
        margin: 0px;
      }
      @media screen and (max-width: 400px) {
        justify-content: center !important;
      }
      .container-col-cards {
        &:nth-child(1),
        &:nth-child(5) {
          .container-primary {
            &::before {
              border-radius: 20px 0px 0px 20px;
              background-color: $color-green-regular;
            }
          }
        }
        &:nth-child(2),
        &:nth-child(4) {
          margin-top: 80px;
          @media screen and (max-width: 991px) {
            margin-top: 0px;
          }
          .container-primary {
            &::before {
              background-color: $color-green-light;
            }
          }
        }
        &:nth-child(2) .container-primary::before {
          border-radius: 20px 20px 0px 20px;
        }
        &:nth-child(4) .container-primary::before {
          border-radius: 20px 20px 0px 0px;
        }
        &:nth-child(3) .container-primary::before {
          border-radius: 0px 20px 0px 20px;
          background-color: $color-green-medium;
        }
        //solo para mobile
        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(5) {
          @media screen and (max-width: 991px) {
            margin-top: -80px;
            position: relative;
          }
          @media screen and (max-width: 400px) {
            margin: 0px;
          }
        }
      }
    }
    .directory-cards-container {
      align-content: center;
      justify-content: center;
      display: flex;
      flex-flow: wrap;
      width: 100%;
      .container-col-cards {
        width: 20%;
        min-width: 190px;
        align-items: center;
        align-content: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 300px;
        position: relative;
        .container-primary {
          height: 100%;
          width: 100%;
          align-items: center;
          align-content: center;
          justify-content: center;
          display: flex;
          overflow: hidden;
          &::before {
            content: "";
            height: 70%;
            width: 100%;
            position: absolute;
            bottom: 0%;
            z-index: 0;
            background-color: yellow;
            border-style: none;
          }
          img {
            height: 90%;
            width: auto;
            z-index: 1;
          }
        }
        .container-secondary {
          position: absolute;
          z-index: 2;
          width: 85%;
          right: 0;
          bottom: 0;
          height: 115px;
          background-color: $color-green-dark;
          border-radius: 15px 0px 0px 15px;
          color: white;
          padding: 10px 10px;
          text-align: left;
          p {
            margin-bottom: 0px;
            font-weight: bold;
            line-height: 1.2;
          }
          .nombre {
            text-transform: uppercase;
            font-size: 14px;
            margin-bottom: 5px;
            &:first-child {
              margin-bottom: 0px;
            }
            @media screen and (max-width: 400px) {
              width: 100%;
            }
          }
          .cargo {
            font-size: 12.5px;
            color: $color-orange;
            margin-bottom: 5px;
            @media screen and (min-width: 1200px) {
              font-size: 14px;
            }
            @media screen and (max-width: 991px) {
              font-size: 12px;
            }
          }
          .ferreteria {
            font-size: 12.5px;
            @media screen and (min-width: 1200px) {
              font-size: 14px;
            }
            @media screen and (max-width: 991px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
