.news-template-container{
    justify-content: center;
}
.not-found-section{
    height: 45vh;
    min-height: 300px;
    align-items: center;
    text-align: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.newsTemplate-title-container{
    height: 240px;
    width: 100%;
    background-color: $color-green-dark;
    display: block !important;
    @media screen and (max-width:1449px){
        height: 160px;
    }
    @media screen and (max-width:1200px){
        height: 195px;
    }
    @media screen and (max-width:991px){
        height: 80px;
    }
    h1{
        width: 100%;
        text-align: center;
        line-height: 1;
        font-size: 40px;
        margin-bottom: 0px;
        position: relative;
        top: 18%;
        color: $color-green-light;
        a{
            font-family: Robotobold !important;
            color: $color-green-light;
            &:hover{
                color: $color-green-light;
            }
            &:active{
                color: $color-green-light;
            }
            &:focus{
                color: $color-green-light;
            }
        }
        @media screen and (max-width:991px){
            top: 0%;
            height: 100%;
            align-items: center;
            display: grid;
        }
        @media screen and (max-width: 768px){
            font-size: 30px;
        }
    }
}
.news-template-section, .news-template-container, .news-template-article{
    position: relative;
}
.news-template-section{
    .news-template-container{
        .news-template-article{
            width: 60%;
            max-width: 1200px;
            @media screen and (max-width:991px){
                width: 100%;
            }
            background-color: white;
            border:solid $color-gray-light .1vh;
            border-top-style: none;
            border-left-style: none;
            .article-header{
                color: white;
                background-color: $color-green-medium;
                position: relative;
                height: 250px;
                width: 100%;
                margin: -1px;
                justify-content: center;
                display: flex;
                flex-direction: column;
                line-height: 1;
                padding: 0% 10%;
                font-weight: bold;
                text-transform: uppercase;
                h1{
                    color: white;
                    font-size: 25px;
                    margin: 0%;
                    width: 80%;
                    @media screen and (min-width:1650px){
                        font-size: 30px;
                    }
                    @media screen and (max-width:991px){
                        width: 100%;
                    }
                    @media screen and (max-width:500px){
                        font-size: 20px;
                    }
                }
                p{
                    font-size: 13px;
                    margin: 0%;
                    line-height: 2;
                    @media screen and (min-width:1650px){
                        font-size: 18px;
                    }
                }          
            }
            .header-img, .content-img{
                overflow: hidden;                         
                img{
                    border-radius: 3vh; 
                    width: 100%;
                }
            }
            .header-img{
                width: 70%;
                max-width: 750px;
                height: 380px;
                position: absolute;
                left: 80%;
                top: -30%;
                align-items: center;
                align-content: center;
                justify-content: center;
                display: flex;  
                @media screen and (min-width:1450px){
                    left: 77%;
                    top: -55%;
                    height: 450px;
                }       
                @media screen and (max-width:1200px){
                    width: 90%;
                    height: 400px;
                    top: -40%;
                    left: 75%;
                }
                @media screen and (max-width:991px){
                    display: none;
                }
            }
            .content-img{
                display: none;
                @media screen and (max-width:991px){
                    display: block;
                    margin-bottom: 25px;
                    width: 70%;
                    margin-left: auto;
                    margin-right: auto;
                    align-items: center;
                    align-content: center;
                    justify-content: center;
                    display: flex;  
                    height: auto;
                    min-height: auto;
                }
                @media screen and (max-width:668px){
                    width: 100%;
                }
            }
            .article-content{
                padding: 100px 10%;
                color: $color-green-dark;
                font-size: 16px;
                line-height: 1.2;
                h1, h2, h3, h4, h5, h6{
                    color: $color-green-dark;
                }
                @media screen and (min-width:1650px){ 
                    font-size: 20px;
                }
                @media screen and (max-width:991px){
                    padding: 40px 10%;
                }
                strong{
                    font-family: Robotoblack !important;
                }
            }
        }
        .news-template-sideArticle{
            background-color: $color-white;
            width: 40%;
            max-width: 800px;
            @media screen and (max-width:991px){
                width: 100%;
            }
            .sideArticle-space{
                height: 250px;
                margin-bottom: 80px;         
                @media screen and (min-width:1650px){
                    margin-bottom: 100px; 
                }
                @media screen and (max-width:991px){
                    display: none;
                }
            }
            .sideArticle-seeAlso{
                line-height: 1;
                justify-content: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                h1{
                    color: $color-green-dark;
                    font-size: 25px;
                    width: 100%;
                    text-align: center;
                    margin: 0;
                    line-height: 2;
                    @media screen and (min-width:1650px){
                        font-size: 30px;
                    }
                }
                .seeAlso-cards-container{
                    @media screen and (max-width:991px){
                        display: flex;
                    }
                    @media screen and (max-width:600px){
                        display: block;
                    }
                    .news-small-card{
                        cursor: pointer;
                        border-radius: 4%;
                        overflow: hidden;
                        position: relative;
                        min-height: 270px;
                        min-width: 270px;
                        height: 280px;
                        width: 280px;
                        box-shadow: 0px 0px 5px -1px $color-gray-black;
                        margin: 50px 30px;
                        @media screen and (min-width:1650px){
                            height: 300px;
                            width: 300px;
                            min-height: 300px;
                            min-width: 300px;
                            margin: 3vh;
                            box-shadow: 0px 0px 2vh -1vh $color-gray-black;
                        }
                        @media screen and (max-width:600px){
                            margin: 30px 0px;
                            min-height: 290px;
                            min-width: 260px;
                            height: 290px;
                            width: 260px;
                        }
                        .img-news-container{
                            height: 50%;
                            background-size: cover;
                            background-position: top;
                            background-repeat: no-repeat;
                        }
                        .text-news-container{
                            height: 50%;
                            padding: 12px;
                            text-transform: uppercase;
                            h3{
                                margin: 8px 0%;
                                font-size: 15px;
                                color: $color-green-dark;
                                @media screen and (min-width:1650px){
                                    font-size: 18px;
                                }
                            }
                            p{
                                margin: 0%;
                                color: $color-green-light;
                                font-weight: bolder;
                                font-size: 12px;
                                @media screen and (min-width:1650px){
                                    font-size: 14px
                                }
                            }
                            .read-more-button{
                                cursor: pointer;
                                height: 25px;
                                width: 100px;
                                position: absolute;
                                right: 0%;
                                bottom: 0%;
                                align-items: center;
                                align-content: center;
                                justify-content: center;
                                display: flex;
                                color: #234031;
                                font-family: Robotoblack !important;
                                font-size: 14px;
                                img{
                                    height: 50%;
                                    margin-right: 8px;
                                }
                                &:hover{
                                    color: $color-green-regular;
                                }
                                @media screen and (min-width:1650px){
                                    height: 30px;
                                    width: 120px;
                                    font-size: 16px;
                                }
                            }
                        }
                        &:first-child{
                            @media screen and (max-width:991px) and (min-width:601px){
                                margin-left: 0%;
                            }
                        }
                        &:last-child{
                            @media screen and (max-width:991px) and (min-width:601px){
                                margin-right: 0%;
                            } 
                        }
                        &:hover{
                            .img-news-container{
                                box-shadow: inset 2px 2px 500px 2px rgba(0, 0, 0, 0.5);
                            }
                        }
                    }
                }         
            }
        }
    }
}