.container-advantage{
    width: 100%;
    min-height: 30px;
    //background-color: $color-white;
    line-height: 1;
    align-items: center;
    text-align: center;
    align-content: center;
    display: block !important;
    padding: 50px 0px;
    .advantage-title{
        text-transform: uppercase;
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        font-size: 140px;
        font-weight: bold;
        position: relative;
        line-height: 1;
        color: rgba($color-gray-light, 0.5);
        overflow: hidden;
        @media screen and (max-width:600px){
            font-size: 80px;
        }
        @media screen and (max-width:460px){
            font-size: 60px;
        }
        h1{
            color: $color-green-regular;
            position: absolute;
            font-size: 30px;
            justify-content: center;
            align-content: center;
            align-items: center;
            text-align: center;
            display: flex;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: 0;
            @media screen and (max-width:600px){
                font-size: 25px;
            }
            @media screen and (max-width:460px){
                font-size: 18px;
            }
        }
    }
    .advantageimg{
        width: 85%;
        max-width: 1650px;
        @media screen and (max-width:1200px){
            width: 100%;
            max-width: 1100px;
        }
        @media screen and (max-width:560px){
            max-width: 350px;
        }
    }
}