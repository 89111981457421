.shownews-container{
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
}
.shownews-container{
    //pagination
    .pagination{
        width: 80%;
        height: 3.4vh;
        min-height: 22px;
        margin-top: 100px;
        margin-bottom: 100px;
        list-style: none;
        justify-content: center;
        display: flex;
        padding: 0px;
        @media screen and (min-width:1920px){
            margin: 15vh 10%;
        }
        @media screen and (max-width:1200px){
            width: 90%;
            margin-left: 5%;
        }
        .next a{
            background-image: url('../../assets/contenido/FerreNoticias/arrowR.png');
        }
        .previous a{
            background-image: url('../../assets/contenido/FerreNoticias/arrowL.png');
        }
        .next a, .previous a{
            text-indent:-999999px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            color: transparent;
            display: inline-block;
            height: 100%;
            width: 3.4vh;
            min-width: 22px;
        }
        li{
            margin: 0px 2vh;
            a{
                font-family: Robotoblack !important;
                font-size: 18px;
                color: $color-green-medium;
                @media screen and (min-width:1650px){
                    font-size: 25px;
                }
                &:hover{
                    color: $color-green-regular;
                }
            }
        }
        .pagination__link--active{
           a{
            color: $color-green-dark;
           }
        }
    }
    //pagination end
    .container-news{
        margin-top: 50px;
    }
    .container-news{
        justify-content: center;
        width: 80%;
        max-width: 1536px;
        border-top-style: none;
        @media screen and (max-width:1200px){
            width: 100%;
        }
        .card-news-container{
            width: 33.3%;
            margin-top: 7vh;
            justify-content: center;
            display: flex;
            @media screen and (max-width:991px){
                width: 50%;
            }
            @media screen and (max-width:680px){
                width: 100%;
            }
            .card-news-info{
                cursor: pointer;
                width: 85%;
                min-width: 300px;
                height: 370px;
                background-color: white;
                position: relative;
                box-shadow: 0px 0px 5px -1px $color-gray-black;
                overflow: hidden;
                border-radius: 2%;
                @media screen and (min-width:1650px){
                    height: 430px;
                }
                @media screen and (max-width:991px){
                    width: 300px;
                }
                @media screen and (max-width:320px){
                    width: 100%;
                    min-width: 100% !important;
                }
                .img-news-container{
                    overflow: hidden;
                    height: 50%;
                    transition: height .6s;
                    background-size: cover;
                    background-position: top;
                    background-repeat: no-repeat;
                    @media screen and (max-width:320px){
                        height: 40%;
                    }
                }
                .text-news-container{
                    height: 50%;
                    transition: height .6s;
                    line-height: 1;
                    padding:15px;
                    @media screen and (max-width:320px){
                        height: 60%;
                    }
                    p{
                        margin: 0px;
                    }
                    .news-date{
                        font-size: 10px;
                        color: $color-green-light;
                        text-transform: uppercase;
                        font-weight: bold;
                        @media screen and (min-width:1650px){
                            font-size: 14px;
                        }
                    }
                    .news-title{
                        margin: 8px 0%;
                        font-size: 18px;
                        color: $color-green-dark;
                        text-transform: uppercase;
                        @media screen and (min-width:1650px){
                            font-size: 22px;
                        }
                    }
                    .news-paragraph{
                        opacity: 0;
                        transition: opacity .6s;
                        font-size: 14px;
                        line-height: 1.3;
                        color: $color-green-dark;
                        @media screen and (min-width:1650px){
                            font-size: 16px;
                        }
                    }
                    .read-more-button{
                        cursor: pointer;
                        position: absolute;
                        right: 15px;
                        bottom: 15px;
                        align-items: center;
                        align-content: center;
                        justify-content: center;
                        display: flex;
                        color: $color-green-dark;
                        font-family: Robotoblack !important;
                        font-weight: bold;
                        font-size: 16px;       
                        img{
                            height: 20px;
                            margin-right: 8px;
                        }
                        @media screen and (min-width:1650px){
                            font-size: 20px;
                        }
                        &:hover{
                            color: $color-green-regular;
                        }
                    }
                }
            }
            &:hover{
                .img-news-container{
                    box-shadow: inset 2px 2px 500px 2px rgba(0, 0, 0, 0.5);
                }
                @media screen and (min-width:768px){
                    .img-news-container{
                        height: 20%;
                    }
                    .text-news-container{
                        height: 80%;
                        .news-paragraph{
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}