.findStore-title{
    align-items: center;
    background-color: $color-green-dark;
    padding: 20px 0px;
    min-height: 130px;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: grid !important;
    @media screen and (max-width:991px){
        padding: 15px 0px;
    }
    h1{
        line-height: 1;
        text-align: center;
        color: white;
        font-size: 35px;
        margin: 0px; 
        @media screen and (min-width: 1650px){
            font-size: 40px;
        }
        .featured-text{
            color: $color-green-light;
            font-family: robotoBold !important;
        }
    }
    h3{
        color: white;
        text-align: center;
        font-size: 18px;
        margin: 0px;
        @media screen and (min-width: 1650px){
            font-size: 22px;
        }
    }
}
.filters-container{
    height: 100px;
    width: 100%;
    background-image: linear-gradient(100deg, $color-green-regular 30%, $color-green-medium 100%);
    align-items: center;
    justify-content: center;
    align-content: center;
    display: flex;
    @media screen and (max-width: 680px){
        height: auto;
        padding: 5px 0px;
    }
    .filters-row{
        height: auto;
        width: 60%;
        max-width: 1150px;
        align-content: center;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row;
        @media screen and (max-width: 1024px){
            width: 80%;
        }
        @media screen and (max-width: 767px){
            width: 95%;
        }
        @media screen and (max-width: 680px){
            flex-direction: column;
        }
        .filter{
            position: relative;
            width: 33.3%;
            height: 30px;
            align-items: center;
            align-content: center;
            display: flex;
            @media screen and (max-width: 680px){
                width: 100%;
                height: 25px;
                margin: 5px 0px;
            }
            select, option{
                font-family: Robotoregular !important;
            }
            option{
                font-size: 12px;
            }
            input, select{
                height: 100%;
                width: 90%;
                background: transparent;
                border-top-style: none;
                border-left-style: none;
                border-right-style: none;
                border-bottom: solid 2px $color-gray-light !important;
                font-size: 14px;
                color: $color-gray-light;
                &:focus{
                    border-style: none;
                    outline: solid .5vh $color-green-regular;
                    color: $color-green-dark;
                    font-size: 14px;  
                }
                @media screen and (max-width: 680px){
                    width: 100%;
                }
            }
            input{
                &::placeholder{
                    font-size: 14px;
                    color: $color-gray-light;
                }
            }
        }
        .search-name{
            display: flex;
            input{
                width: 80% !important;
                margin-right: 5%;
                @media screen and (max-width: 680px){
                    width: 90% !important;
                }
            }
            .search-icon{
                width: 30px;
                height: 30px;
                border-radius: 100%;
                background-color: $color-green-light;
                color: white;
                align-content: center;
                align-items: center;
                justify-content: center;
                display: flex;
                @media screen and (max-width: 680px){
                    width: 25px;
                    height: 25px;
                }
                img{
                    height: 70%;
                }
            }
            .suggestion-list{
                width: 80% !important;
                @media screen and (max-width: 680px){
                    width: 90% !important;
                }
            }
        }
    }
    .suggestion-list{
        width: 90%;
        position: absolute;
        top: 101%;
        list-style: none;
        margin: 0px;
        padding: 0px;
        z-index: 5;
        background: white;
        border: 1px solid $color-gray-light;
        max-height: 200px;
        overflow: hidden;
        overflow-y: scroll;
        @media screen and (max-width: 680px){
            width: 100%;
        }
        li{
            padding: 2px 0px;
            font-size: 14px;
            cursor: pointer;
            &:hover{
                background-color: $color-green-regular;
                color: white;
            }
        }
    }
}
.sidebar-map{
    width: 25%;
    max-width: 520px;
    height: 90%;
    max-height: 100%;
    overflow: hidden;
    z-index: 4;
    overflow-y: auto;
    padding: .5% .5%;
    position: absolute;
    right: 2%;
    scroll-behavior: smooth;
    align-items: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    scrollbar-width: thin;
    scrollbar-color: $color-green-regular #F5F5F5;
    @media screen and (max-width: 1230px){
        width: 30%;
    }
    @media screen and (max-width: 1024px){
        width: 35%;
    }
    @media screen and (max-width: 767px){
        position: initial;
        width: 95%;
        height: 550px;
        padding: 5px 0px;
    }
    &::-webkit-scrollbar-track
    {   box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
        border-radius: 10vh;
    }
    
    &::-webkit-scrollbar
    {
        width: 1vh;
        min-width: 5px;
        background-color: #F5F5F5;
    }
    
    &::-webkit-scrollbar-thumb
    {
        height: 10%;
        border-radius: 5vh;
        background-color: $color-green-regular;
    }
    
}
.container-navigation-maps{
    background-color: $color-white;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    height: 100vh;
    min-height: 500px;
    @media screen and (max-width: 767px){
        flex-direction: column;
        height: auto;
    }
}
.inputsAndMap{
    height: 100%;
    width:100% ;
    position: relative;
    @media screen and (max-width: 767px){
        height: 450px;
    }
    .input-search{
        position: absolute;
        z-index:2;
        top: 2%;
        left: 15%;
        width: 30%;
        max-width: 600px;
        height: auto;
        img{
            position:absolute;
            height: 70%;
            top: 15%;
            right: 2%;
        }
        @media screen and (max-width: 767px){
            width: 50%;
            left: 25%;
        }
    }
    #searchMap{
        width: 100%;
        padding: 1%;
        -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0,0.2);
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
        border: 1px #CCC solid;  
        align-items: center;
        align-content: center;
        text-align: center;
        display: flex;
        font-size: 14px; 
            @media screen and (min-width:1650px){
               font-size: 18px;
            }    
            @media screen and (max-width:991px){
                font-size: 13px;
            }
        &:hover{
            box-shadow: 0px 0px 3px gray;
        }
        &:focus{
            outline: solid 2px $color-green-regular;
            color: $color-green-dark;
            font-size: 16px;
            @media screen and (min-width:1920px){
                font-size: 2vh;
            }    
            @media screen and (max-width:600px){
                font-size: 15px;
            }    
        }
    }
}
//customControls
.custom-controls{
    display: none;
}
.zoom-control{
    bottom: 2% !important;
    left: 0% !important;
    right: 0% !important;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    .zoom-control-in, .zoom-control-out{
         cursor: pointer;
        background-color: transparent;
        border: none;
        align-items: center;
        align-content: center;
        justify-content: center;
        display: flex;
        img{
            height: 6vh;
            min-height: 35px;
        }
    }
}
//container map
.findStore-map-container{
    height: 100%;
}
.not-location{
    width: 100%;
    height: 200px;
    line-height: 1;
    font-size: 14px;
    background-color: white;
    color: $color-green-dark;
    border: solid 2px red;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    padding: 20px;
}
.select-card{
    border: 2px solid $color-green-regular;
    background-color: $color-white;
}
.infowindow-sidebar{
    margin: 2vh 0vh;
    width: 100%;
    @media screen and (max-width: 767px){
        width: 300px;
        margin: 10px 0px !important;
    }
    @media screen and (max-width: 300px){
        width: 98%;
    }
}
.infowindow-content{
    min-width: 300px;
    max-width: 45vh;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 480px){
        min-width: 100%;
    }
    .infowindow-img{
        width: 90%;
        margin-bottom: 10px;
    }
    .viewInGoogleMaps{
        width: 100%;
        align-items: center;
        align-content: center;
        justify-content: center;
        display: flex;
        padding-top: 10px;    
        div{
            font-weight: bold;
            min-width: 80px;
            border: solid 2px $color-green-regular;
            padding: 5px;
            color: $color-green-regular;
            &:hover{
                color: $color-green-dark;
                border-color: $color-green-dark;
            }
        }
    }
}
.infowindow-content, .infowindow-sidebar{
    padding: 20px;
    margin: 20px 0vh;
    background-color: white;
    border-radius: 1vh;
    @media screen and (max-width: 991px){
        padding: 20px;
        margin: 15px 0px;
    }
    &:first-child{
        margin-top: 0%;
    }
    &:last-child{
        margin-bottom: 0%;
    }
    .text-infowindow{
        line-height: 1;
        font-size: 13px;
        @media screen and (min-width: 1650px){
            font-size: 17px;
        }
        .infoWindow-store-commune{
            color: $color-green-dark;
            font-size: 25px;
            margin-bottom: 8px;
            @media screen and (min-width: 1650px){
                font-size: 30px;
                margin-bottom: 10px;
            }
        }
        .infoWindow-store-name{
            color: $color-green-regular;
            text-transform: uppercase;
            font-size: 14px;
            @media screen and (min-width: 1650px){
                font-size: 18px;
            }
        }
        p{
            margin-bottom: 5px;
            @media screen and (min-width: 1650px){
                margin-bottom: 8px;
            }
        }
        .infoWindow-store-adress{
           font-weight: bold;
        }
        .infoWindow-store-tel{
            color: $color-green-light;
            font-weight: bold;
            display: flex;
            flex-flow: wrap;
            line-height: 1.2;
            a{
                color: $color-green-light;
                margin-left: 10px;
                &:hover{
                    color: $color-green-regular;
                }
            }
        }
        .infoWindow-store-web{
            color: $color-green-dark;
            font-weight: bold;
            &:hover{
                color: $color-green-dark;
            }
            &:active{
                color: $color-green-dark;
            }
        }
    }
}
.gm-ui-hover-effect{
    top: 2% !important;
    right: 2% !important;
    width: auto !important;
    height: auto !important;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex !important;
    img{
        width: 25px !important;
        height: 25px !important;
        margin: 0px !important;
    }
}