.title-section-container {
  height: 17vh;
  min-height: 110px;
  width: 100%;
  align-items: center;
  background-color: $color-green-dark;
  h1 {
    width: 100%;
    text-align: center;
    color: $color-green-light;
    font-size: 5vh;
    margin: 0px;
    height: 100%;
    align-items: center;
    align-content: center;
    display: inline-grid;
    line-height: 1;
    @media screen and (max-width: 600px) {
      font-size: 4vh;
    }
  }
}
