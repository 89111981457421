.squad-chilemat{
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    img{
        width: 100%;
        max-width: 2000px;
    }
}
.container-management{
    background-color: white;
    width: 100%;
    height: auto;
    text-align: center;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    @media screen and (max-width:991px){
        padding-bottom: 40px;
    }
    img{
        width: 100%;
        max-width: 1200px;
    }
    .management-title{
        text-transform: uppercase;
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        font-size: 100px;
        font-weight: bold;
        position: relative;
        line-height: 1;
        color: rgba($color-gray-light, 0.5);
        overflow: hidden;
        @media screen and (max-width:680px){
            font-size: 80px;
        }
        @media screen and (max-width:550px){
            font-size: 70px;
        }
        @media screen and (max-width:470px){
            font-size: 60px;
        }
        @media screen and (max-width:410px){
            font-size: 50px;
        }
        @media screen and (max-width:350px){
            font-size: 40px;
        }
        h1{
            color: $color-green-regular;
            position: absolute;
            font-size: 40px;
            justify-content: center;
            align-content: center;
            align-items: center;
            text-align: center;
            display: flex;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: 0;
            @media screen and (max-width:991px){
                font-size: 35px;
            }
            @media screen and (max-width:600px){
                font-size: 30px;
            }
        }
    }
    .row-management{
        margin: 180px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        @media screen and (max-width:991px){
            margin-top: 40px;
            margin-bottom: 40px;
        }
        .management-container{
            transform: rotate(
            -45deg
            );
            width: 95%;
            max-width: 600px;
            align-items: center;
            display: flex;
            flex-flow: wrap;
            @media screen and (max-width:991px){
                transform: rotate(
                    0deg
                );
                margin-top: 10px;
                width: 90%;
                justify-content: center;
            }
            @media screen and (max-width:500px){
                width: 100%;
            }
            .container-col-cards{
                border: none;
                float: left;
                width: 32%;
                height: 0px !important;
                padding-bottom: 32% !important;
                margin: 3px;
                border-radius: 15px;
                position: relative;
                transition: all .4s ease;
                background-color: white;
                @media screen and (max-width:991px){
                    width: 25%;
                    padding-bottom: 25% !important;
                    margin: 5px !important;
                }
                @media screen and (max-width : 680px) {
                    width: 40%;
                    padding-bottom: 40% !important;
                }
                @media screen and (max-width : 350px) {
                    width: 65%;
                    padding-bottom: 65% !important;
                }
                 //Alitas
                //alitas izquierdas
                    &::before{
                        content: "";
                        position: absolute;
                        z-index: -1;
                        border-radius: 15px;
                        @media screen and (min-width:992px){
                            border: solid 1px rgba($color-gray-light, 0.8);
                            width: 100%;
                            height: 100%;
                            margin-top: -104%;
                            margin-left: -154%;
                        }
                    }
                // alitas derechas
                    &::after{
                        content: "";
                        position: absolute;
                        z-index: -1;
                        border-radius: 15px;
                        @media screen and (min-width:992px){
                            border: solid 1px rgba($color-gray-light, 0.8);
                            width: 100%;
                            height: 100%;                
                            margin-top: 104%;
                            margin-left: 54%;
                        }
                    }
                    &:nth-child(5)::before,&:nth-child(6)::before,&:nth-child(8)::before,&:nth-child(9)::before,
                    &:nth-child(1)::after, &:nth-child(2)::after, &:nth-child(4)::after, &:nth-child(5)::after{
                        border-style: none !important;
                    }
                //end alitas 
                /*Posiciones las tarjetas*/
                &:nth-child(11), &:nth-child(12),&:nth-child(10){
                  display: none;
                }
                

                div{
                    position: relative;
                }
                &:nth-child(2) .container-primary, &:nth-child(5) .container-primary, &:nth-child(9) .container-primary{
                    border: 2px solid $color-green-medium;
                }
                &:nth-child(6) .container-primary, &:nth-child(10) .container-primary{
                    border: 2px solid $color-green-regular;
                }
                &:nth-child(1) .container-primary, &:nth-child(4) .container-primary, &:nth-child(8) .container-primary, &:nth-child(12) .container-primary{
                    border: 2px solid $color-orange;
                }
                &:nth-child(3) .container-primary, &:nth-child(7) .container-primary, &:nth-child(11) .container-primary{
                    border: 2px solid $color-green-light;
                }
                .container-primary{
                    height: 100%;
                    width: 100%; 
                    border-radius: 15px;
                    position: absolute;
                    z-index: 0;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    overflow: hidden;
                    border: none;
                    img{
                        display: block;
                        height: 125%;
                        width: auto;
                        transform: rotate(45deg) translate(0, 15px) scale(1.2);
                        @media screen and (max-width:991px){
                            transform: rotate(0) translate(0, 0);
                            height: 110%;
                        }
                    }
                }
                .container-secondary{
                    position: absolute;
                    top: 0px;
                    bottom: 0px;
                    left: 0px;
                    right: 0px;
                    z-index: 2;
                    transform: rotate(45deg) translate(0px, 0px);
                    justify-content: center;
                    text-align: center;
                    align-items: center;
                    display: flex;
                    transition: all .6s ease;
                    @media screen and (max-width:991px){  
                        transform: rotate(0) translate(0, 0);
                    }
                    &::after{
                        content: "";
                        position: absolute;
                        z-index: -1;
                        border-radius: 15px;
                        transform: rotate(45deg) translate(0px, 0px);
                        background-image: linear-gradient(-45deg, $color-green-dark 48%, transparent 48%);
                        width: 100%;
                        height: 100%;
                        @media screen and (max-width:991px){  
                            transform: rotate(0) translate(0, 0);
                            background-image: linear-gradient(0deg, $color-green-dark 45%, transparent 45%);
                        }
                    }
                    .info-gerente{
                        position: absolute;
                        z-index: 1;
                        top: 58%;
                        align-items: center;
                        justify-content: center;
                        display: flex;
                        flex-direction: column;
                        .nombre{
                            text-transform: uppercase;
                            font-size: 14px;
                            color: white;
                            margin-bottom: 3px;
                            @media screen and (min-width : 1992px) {
                                font-size: 16px;
                            }
                            @media screen and (max-width:1480px){
                              font-size: 12px;
                            }
                            @media screen and (max-width : 991px) {
                                font-size: 12px;
                                width: 100%;
                            }
                        }
                        .cargo{
                            width: 75%;
                            font-size: 13px;
                            color: $color-orange;
                            @media screen and (min-width : 1992px) {
                                font-size: 14px;
                            }
                            @media screen and (max-width : 1480px) {
                                font-size: 9px;
                            }
                            @media screen and (max-width:991px){  
                                width: 99%;
                            }
                            @media screen and (max-width : 991px) {
                                font-size: 12px;
                            }
                            @media screen and (max-width : 300px) {
                                font-size: 10px;
                            }
                        }
                        p{
                            line-height: 1;
                            margin: 0;
                            font-weight: bold;
                            transition: all 1s ease;
                        }
                    }
                    
                }
            }
        }
    }
   
}
