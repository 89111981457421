.HomeCardsBenefits-section{
    width: 100%;
    padding: 50px 0%;
	justify-content: center;
	align-items: center;
    display: flex;
    flex-direction: column;
    .HomeCardsBenefits-title{
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        font-size: 100px;
        font-weight: bold;
        position: relative;
        line-height: 1;
        color: rgba($color-gray-light, 0.5);
        overflow: hidden;
        @media screen and (max-width:680px){
            font-size: 80px;
        }
        @media screen and (max-width:550px){
            font-size: 70px;
        }
        @media screen and (max-width:470px){
            font-size: 60px;
        }
        @media screen and (max-width:410px){
            font-size: 50px;
        }
        @media screen and (max-width:350px){
            font-size: 40px;
        }
        h1{
            color: $color-green-regular;
            position: absolute;
            font-size: 35px;
            justify-content: center;
            align-content: center;
            align-items: center;
            text-align: center;
            display: flex;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: 0;
            @media screen and (max-width:991px){
                font-size: 30px;
            }
            @media screen and (max-width:600px){
                font-size: 25px;
            }
            @media screen and (max-width:460px){
                font-size: 18px;
            }
        }
    }
    .HomeCardsBenefits-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .cardsBenefits-container{
	    transform: rotate(
        -45deg
        );
        width: 55%;
        max-width: 1000px;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-flow: wrap;
        margin-top: -50px;
        @media screen and (min-width:1280px){
            width: 40%;
        }
        @media screen and (max-width:930px){
            width: 65%;
        }
        @media screen and (max-width:840px){
            width: 70%;
        }
        @media screen and (max-width:767px){
            transform: rotate(
                0deg
            );
            margin-top: 10px;
            width: 90%;
        }
        .container-col-cards{
            float: left;
	        width: 23%;
            height: 0px !important;
            padding-bottom: 23% !important;
            margin: 5px;
            border-radius: 15px;
            position: relative;
            transition: all .4s ease;
            &::after{
                content: "";
                position: absolute;
                z-index: -1;
                border-radius: 15px;
                @media screen and (min-width:768px){
                    border: solid 1px rgba($color-gray-light, 0.8);
                    width: 100%;
                    height: 100%;
                    margin-top: 214%;
                    margin-left: 206%;
                }
            }
            &::before{
                content: "";
                position: absolute;
                z-index: -1;
                border-radius: 15px;
                @media screen and (min-width:768px){
                    border: solid 1px rgba($color-gray-light, 0.8);
                    width: 100%;
                    height: 100%;
                    margin-top: -214%;
                    margin-left: -207%;
                }
            }
            @media screen and (max-width:767px){
                width: 30%;
                padding-bottom: 30% !important;
                margin: 5px !important;
            }
            @media only screen and (max-width : 399px) {
                width: 40%;
                padding-bottom: 40% !important;
            }
            @media only screen and (max-width : 350px) {
                width: 60%;
                padding-bottom: 60% !important;
            }
            &:nth-child(2){
                margin-right: 46%;
            }
            &:nth-child(5){
                margin-right: 23%;
            }
            &:nth-child(10){
                margin-left: 25%;
            }
            div{
                position: relative;
            }
            &:nth-child(1) .container-primary::after, &:nth-child(7) .container-primary::after, &:nth-child(9) .container-primary::after{
                background-color: rgba($color-orange, 0.7);
            }
            &:nth-child(4) .container-primary::after, &:nth-child(6) .container-primary::after, &:nth-child(8) .container-primary::after{
                background-color: rgba($color-green-light, 0.7);
            }
            &:nth-child(5) .container-primary::after, &:nth-child(12) .container-primary::after{
                background-color: rgba($color-green-dark, 0.7);
            }
            .container-primary{
                height: 100%;
                width: 100%; 
                border-radius: 15px;
                position: absolute;
                z-index: 0;
                align-items: center;
                justify-content: center;
                display: flex;
	            overflow: hidden;
                &::after{
                    content: '';
                    background-color: rgba($color-green-regular, 0.7);
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 3;
                    cursor: pointer;
                    transition: all .6s ease;
                }
                &:nth-child(2)::after{
                    background-color: rgba($color-gray-light, 0.8);
                }
                img{
                    display: block;
                    height: 140%;
                    transform: rotate(45deg) translate(0, 15px) scale(1.2);
                    @media screen and (max-width:767px){
                        transform: rotate(0) translate(0, 0);
                    }
                }
            }
            .container-secondary{
                position: absolute;
                top: 0px;
                bottom: 0px;
                left: 0px;
                right: 0px;
                z-index: 2;
                transform: rotate(45deg) translate(0px, 0px);
                justify-content: center;
                text-align: center;
                align-items: center;
                display: flex;
                line-height: 1.2;
                text-transform: uppercase;
                transition: all .6s ease;
                @media screen and (max-width:767px){  
                    transform: rotate(0) translate(0, 0);
                    padding: 10px;
                }
                p{
                    width: 75%;
                    min-width: 130px;
                    color: white;
                    margin: 0;
                    position: relative;
                    font-weight: bold;
                    font-size: 14px;
                    transition: all 1s ease;
                    @media only screen and (min-width : 1650px) {
                        font-size: 16px;
                    }
                    @media only screen and (max-width : 550px) {
                        font-size: 12px;
                        min-width: 100% !important;
                    }
                }
            }
        }
    }
}