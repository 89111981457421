.section-info-home{
    width: 100%;
    align-items: center;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    padding: 50px 0px;
    .info-home-img{
        margin: 50px 0px;
        width: 100%;
        max-width: 2000px;
        margin: 0px auto;
        @media screen and (max-width:1200px){
            width: 100%;
            max-width: 1100px;
        }
        @media screen and (max-width:560px){
            max-width: 350px;
        }
    }
}