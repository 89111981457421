.container-view-supplier{
    width: 100%;
    margin: 0px auto !important;
    max-width: 1920px;
    padding: 50px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    display: flex;
    @media screen and (max-width:1650px){
        padding: 50px 20px;
    }
    .container-supplier{
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: row;
        flex-flow:wrap;
        min-width: 100%;
        max-width: 100%;
        @media screen and (max-width:991px){
           flex-direction: column;
           align-items: center;
        }
        .container-supplier-text{
            width: 35%;
            display: flex;
            justify-content: center;
            align-content: center;
            padding-bottom: 50px;
            @media screen and (max-width:991px){
                width: 100%;
            }
            .container-supplier-info{
                width: 100%;
                max-width: 400px;
                height: fit-content !important;
                max-height: 500px;
                background-color: $color-green-regular;
                padding: 15px;
                line-height: 1.2;
                border-radius: 20px 0px 20px 20px;
                float: right;
                
                h1{
                    font-weight: bolder;
                    color: $color-white;
                    text-align: center;
                    font-size: 100px;
                    line-height: 1;
                    margin-bottom: 2px;
                    @media screen and (max-width:400px){
                        font-size: 80px;
                    }
                }
                h2{
                    color:$color-orange;
                    text-align: center;
                    font-size: 35px;
                    @media screen and (max-width:400px){
                        font-size: 25px;
                    }
                }
                p{
                    color:$color-white;
                    font-size: 18px;
                    text-align: left;
                    @media screen and (max-width:400px){
                        font-size: 16px;
                    }
                }
                .row-supplier-info{
                    width: 100%;
                    max-width: 350px;
                    margin: 0px auto;
                    display: flex;
                    align-items: center;
                    padding: 10px 0px;
                    @media screen and (max-width:400px){
                        flex-direction: column;
                        max-width: 230px;
                        justify-content: center;
                    }
                    .img-supplier-info{
                        margin-right: 15px;
                        img{
                            height: 95px;
                        }
                    }
                }
            }
        }
        .container-supplier-logo{
            width: 65%;
            padding-left: 20px;
            @media screen and (min-width:992px){    
                padding-left: 20px;
            }
            @media screen and (max-width:991px){
                width: 100%;
                max-width: 730px;
            }
            .container-supplier-img{
                display: flex;
                flex-flow: wrap;
                flex-direction: row;                
                justify-content: center;
                align-items: center;
                align-content: center;
            }
            .container-logo{
                width: 17%;
                margin: 0px 5px 15px 5px;
                border-radius: 15px;
                overflow: hidden;
                box-shadow: 0px 0px 15px 0px $color-gray-light;
                cursor: pointer;
                @media screen and (max-width:1200px){
                    width: 23%;
                }
                @media screen and (max-width:767px){
                    width: 45%;
                }  
                &:hover{
                    box-shadow: 0px 0px 20px 2px $color-orange;
                }
                img{
                    height: auto;
                    width: 100%;
                }
            }
        }
    }
    
}