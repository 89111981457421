.ContactForm-section{
    background-image: url("../../assets/contenido/Formulario\ Contacto/fondo.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;  
    min-height: 100vh;
    padding: 40px 0vh;
    align-items: center;
    justify-content: center;
    display: flex;
    @media screen and (max-width:991px){
        padding: 30px 0%;
    }
    .ContactForm-content{
        width: 70%;
        max-width: 1160px;
        overflow: hidden;
        border-radius: 2vh;
        box-shadow: 0px 0px 5px -1px $color-gray-black;
        @media screen and (max-width:1134px){
            width: 80%;
        }
        @media screen and (max-width:768px){
            width: 90%;
        }
        @media screen and (max-width:600px){
            width: 100%;
        }
        .ContactForm-Info{
            padding: 3% 8%;
            background-image: linear-gradient(138deg, $color-green-regular 50%, $color-green-dark 89%);
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            @media screen and (max-width:991px){
                padding: 40px 8%;
            }
            h1, p{
                color: white;
                width: 100%;
                text-align: left;
            }
            h1{
                font-size: 40px;
                line-height: 1;
                text-transform: uppercase;
                @media screen and (max-width:1040px){
                    font-size: 35px;
                }
                @media screen and (max-width:600px){
                    font-size: 30px;
                }
                @media screen  and (min-width:1650px){
                    font-size: 45px; 
                }
            }
            p{
                font-size: 16px;
                width: 80%;
                @media screen and (min-width:1650px){
                    font-size: 20px; 
                }
            }

            .text-marked{
                font-family: Robotobold !important;
                color: $color-green-light;
            }
        }
        .form-container{
            padding: 3% 8%;
            background-color: white;
            color: $color-gray-black;
            @media screen  and (min-width:1650px){
                padding: 3% 10%;
            }
            @media screen and (max-width:991px){
                padding: 40px 8%;
            }
            #ContactForm-message-validation{
                font-size: 16px; 
                color: red;
                @media screen and (min-width:1650px){
                    font-size: 18px;
                }    
                @media screen and (max-width:991px){
                    font-size: 15px;
                }
            }
            .input-comuna{
                margin-left: 7%;
                width: 48%;
                @media screen and (max-width:600px){
                    width: 100%;
                    margin-left: 0%;
                }
            }
            .input-comuna, .input-PhoneNumber{
                float: left;
            }
            .input-PhoneNumber{
                width: 45%;
                align-items: center;
                justify-content: center;
                align-content: center;
                display: flex;
                text-align: center;
                @media screen and (max-width:600px){
                    width: 100%;
                }
                label{
                    width: 25%;
                    margin-right: 3%;
                    display: inline-block;
                    justify-content: center;
                    text-align: center;
                    align-items: center;
                    align-content: center;
                    display: flex;
                    border-bottom: solid 2px $color-gray-light !important;
                }
                input{
                    width: 72%;
                    margin-bottom: 0%;
                }
                input, label{
                    height: 100%;
                }
            }
            input, .input-PhoneNumber, select{
                height: 40px;
                margin-bottom: 20px;
                font-size: 16px; 
                @media screen and (min-width:1650px){
                    height: 50px;
                    margin-bottom: 25px;
                }
                @media screen and (max-width:991px){
                    height: 30px;
                    margin-bottom: 15px;
                    font-size: 15px;
                }
            }
            input{
                border-bottom: solid 2px $color-gray-light !important;  
                width: 100%;  
                border-top-style: none;
                border-left-style: none;
                border-right-style: none;
                &:hover{
                    box-shadow: 0px 0px 3px gray;
                }
                &:focus{
                    border-style: none;
                    outline: solid .5vh $color-green-regular;
                    color: $color-green-dark;
                    font-size: 16px;
                    @media screen and (min-width:1650px){
                        font-size: 18px;
                    }    
                    @media screen and (max-width:600px){
                        font-size: 15px;
                    }    
                }
            }
            select{
                width: 100%;
                margin-bottom: 15px;
                border-bottom: solid 2px $color-gray-light !important;  
                border-top-style: none;
                border-left-style: none;
                border-right-style: none;
                &:hover{
                    box-shadow: 0px 0px 3px gray;
                }
                &:focus{
                    border-style: none;
                    outline: solid .5vh $color-green-regular;
                    color: $color-green-dark;
                        font-size: 16px;
                        @media screen and (min-width:1650px){
                            font-size: 18px;
                        }    
                        @media screen and (max-width:600px){
                            font-size: 15px;
                        }    
                }
            }
            textarea{
                height: 130px;
                width: 100%;
                display: inline-block;
                resize:none;
                border: solid 2px $color-gray-light;
                margin-bottom: 20px;
                @media screen and (min-width:1650px){
                    height: 140px;
                    margin-bottom: 25px;
                }
                @media screen and (max-width:991px){
                    height: 110x;
                    margin-bottom: 15px;
                    font-size: 15px;
                }
                &:hover{
                    box-shadow: 0px 0px 3px gray;
                }
                &:focus{
                    outline: solid .5vh $color-green-regular;
                    color: $color-green-dark;
                    font-size: 16px;
                    border-style: none;
                    @media screen and (min-width:1650px){
                        font-size: 18px;
                    }    
                    @media screen and (max-width:600px){
                        font-size: 15px;
                    }    
                }
            }
            .form-button{
                border: none;
                width: 100%;
                padding: 2% 0% !important;
                font-size: 16px;
                color: white;
                font-weight: bold;
                background-color: $color-green-regular;
                text-align: center;
                align-content: center;
                align-items: center;
                justify-content: center;
                display: flex;
                padding: 0px;
                cursor: pointer;
                @media screen and (max-width:600px){
                    padding: 20px 0px !important;
                }
                @media screen and (max-width:600px){
                    font-size: 15px;
                }
                @media screen and (min-width:1650px){
                    padding: 40px !important;
                    font-size: 20px;
                }
                span{
                height: 55%;
                display: inline-block;
                margin-left: 10%;
                    @media screen and (max-width:500px){
                        width: 15%;
                     }
                    .send{
                        width: 20px;
                        @media screen and (min-width:1650px){
                            width: 30px;
                        }
                    }
                }
                &:hover{
                    background-color: $color-green-light;
                }
            }
        }
    }
}